import { React, useEffect } from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";

// We're using Gutenberg so we need the block styles
// these are copied into this project due to a conflict in the postCSS
// version used by the Gatsby and @wordpress packages that causes build
// failures.
// @todo update this once @wordpress upgrades their postcss version
import "../css/@wordpress/block-library/build-style/style.css";
import "../css/@wordpress/block-library/build-style/theme.css";

import Layout from "../components-pl/layout";
import Seo from "../components-pl/seo";
import Share from "../components/share";

const BlogPostTemplate = ({ data: { previous, next, post } }) => {
  const featuredImage = {
    data: post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData,
    alt: post.featuredImage?.node?.alt || ``,
  };

  useEffect(() => {
    if (post.translations[0]?.slug) {
      document.getElementById("lang-switch").href =
        "/" + post.translations[0].slug + "/";
    }
    window.onscroll = function () {
      if (window.scrollY >= 45) {
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.top = "0px";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.position = "fixed";
        document.querySelector("main").style.marginTop = "45px";
      } else {
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.top = "0px";
        document.querySelector(
          "#gatsby-focus-wrapper > header > div"
        ).style.position = "relative";
        document.querySelector("main").style.marginTop = "0px";
      }
      let mybtn = document.getElementById("back-to-top");
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        mybtn.style.display = "block";
      } else {
        mybtn.style.display = "none";
      }
    };
  });

  function strip(html) {
    // Replace all HTML tags with an empty string
    return html.replace(/<\/?[^>]+(>|$)/g, "").slice(0, 200) + "...";
  }

  return (
    <Layout>
      <Seo
        title={post.title}
        description={strip(post.excerpt)}
        ogImage={
          "https://shroom4you.com" +
          post.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
            .images.fallback.src
        }
        author={post.author.node.name}
      />

      <article
        className="blog-post content global-wrapper"
        itemScope
        itemType="http://schema.org/Article"
        style={{ paddingTop: "0px" }}
      >
        <header style={{ marginTop: "75px" }}>
          <h1 itemProp="headline" className="spec-h">
            {parse(post.title)}
          </h1>
          <p>{post.date}</p>
          <p>
            <Link
              to={parse(post.categories.nodes[0].uri)}
              className="button btn-dark-outline"
            >
              {parse(post.categories.nodes[0].name)}
            </Link>
          </p>

          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.data && (
            <GatsbyImage
              image={featuredImage.data}
              alt={featuredImage.alt}
              style={{ marginBottom: 50, borderRadius: 20 }}
            />
          )}
        </header>
        {/*<code>
        Add table of contents
        <br />
        Develop seo metadata
        <br />
        </code>*/}

        {!!post.content && (
          <section itemProp="articleBody">{parse(post.content)}</section>
        )}

        <hr />
        {/*<code>
        Develop bio -  desctiption / name / image / role
        </code>*/}
        <p className="subtitle">
          Publikacja: <b>{post.author.node.name}</b>
        </p>
        <p className="social-share__title">
          Udostępnij artykuł na portalach społecznościowych!
        </p>
        <Share />
        <p>Tagi:</p>
        <p className="columns" style={{ marginLeft: "0", marginTop: "5px" }}>
          {post.tags
            ? post.tags.nodes.map((tag) => {
                return (
                  <>
                    &nbsp;
                    <span className="tag is-small is-black">{tag.name}</span>
                    &nbsp;
                  </>
                );
              })
            : null}
        </p>

        <footer>
          <nav className="blog-post-nav">
            <ul
              style={{
                display: `flex`,
                flexWrap: `wrap`,
                justifyContent: `space-between`,
                listStyle: `none`,
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.uri} rel="prev">
                    ← {parse(previous.title)}
                  </Link>
                )}
              </li>

              <li>
                {next && (
                  <Link to={next.uri} rel="next">
                    {parse(next.title)} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </footer>
      </article>
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostByIdPL(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      translations {
        slug
      }
      date(formatString: "DD.MM.YYYY")
      author {
        node {
          avatar {
            url
          }
          name
        }
      }
      tags {
        nodes {
          name
        }
      }
      categories {
        nodes {
          name
          uri
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: TRACED_SVG
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    previous: wpPost(
      id: { eq: $previousPostId }
      language: { code: { eq: PL } }
    ) {
      uri
      title
    }
    next: wpPost(id: { eq: $nextPostId }, language: { code: { eq: PL } }) {
      uri
      title
    }
  }
`;
